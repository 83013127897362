<!--
 * @Author: Dyf
 * @Date: 2023-11-07 11:50:00
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-20 14:41:35
 * @Description: 课程学习
-->
<style lang="scss" scoped>
.study-page {
  @include innerPage($p: 32px 0 0);
  @include pageTitle(0);
  .playback {
    width: 70.13%;
    height: calc(100% - 70px);
    margin: 32px auto 0;
    .medium {
      width: 100%;
      height: 100%;
      border-radius: 30px;
    }
    img {
      width: 100%;
      height: auto;
      border-radius: 30px;
    }
  }
}
@media screen and (max-width: 1200px) {
  .study-page {
    @include innerPage($p: 18px 0 0);
  }
}
</style>

<template>
  <section class="study-page">
    <div class="page-title">
      <h3>{{ study_data.plstu_cur_cha_title }}</h3>
      <el-button type="custom_primary" size="small" @click="$router.go(-1)">
        返回
      </el-button>
    </div>
    <div class="playback">
      <div class="medium">
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { formatFile } from "@utils";
export default {
  data() {
    return {
      study_data: {},
      /* 视频播放器配置 */
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        poster: "", // 视频封面
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controls: true,
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
        },
      },
    };
  },
  created() {
    let study_data = sessionStorage.getItem("study_data");
    this.study_data = JSON.parse(study_data);
    this.playerOptions = {
      ...this.playerOptions,
      sources: [
        {
          type: "video/mp4",
          src: formatFile(this.study_data.plstu_cur_cha_url),
        },
      ],
    };
  },
};
</script>